import { Card, CardBody, CardTitle, CardText, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const InfoCard = ({ icon, color, label, value }) => {
  return (
    <Card color={color} inverse>
      <CardBody>
        <Row>
          <Col sm="4">
            <FontAwesomeIcon icon={icon} size="4x" className="me-2" />
          </Col>
          <Col sm="8">
            <CardTitle tag="h5">{label}</CardTitle>
            <CardText>
              {value}
            </CardText>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default InfoCard;

import { Navbar, Collapse, Nav, NavItem } from "reactstrap";
import Logo from "./Logo";

const Header = () => {

  return (
    <Navbar color="dark" light expand="md">
      <Collapse navbar>
        <Nav className="me-auto" navbar>
          <NavItem>
            <Logo/>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default Header;

import LogoDark from "../assets/images/xerolite.png";
import { Link } from "react-router-dom";  

const Logo = () => {
  return (
    <Link to="/">
        <img src={LogoDark} alt="Xeroflex" style={{ width: '130px', height: 'auto' }} />
    </Link>
  );
};

export default Logo;

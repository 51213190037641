import { useState, useEffect } from "react";
import Moment from 'react-moment';
import axios from 'axios';
import { Card, CardBody, CardTitle, ListGroup, ListGroupItem } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faBellConcierge } from "@fortawesome/free-solid-svg-icons";
import AlertPopup from "./AlertPopup";

const Alerts = () => {
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  
  useEffect(() => {
    fetchMessages();
    const intervalId = setInterval(fetchMessages, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);



  const fetchMessages = async () => {
    try {
      const response = await axios.get('/api/logs');
      setMessages(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleShowPopup = (message) => {
    setMessage(message);
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };
  

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h5"><FontAwesomeIcon icon={faBell} /> Incoming Alert
        </CardTitle>

        <ListGroup flush className="mt-4">
          {messages?.map((message, index) => (
            <ListGroupItem
              className="list-group-item-action"
              key={index}
              onClick={() => handleShowPopup(message)}
              tag="a"
            >
              <div className="d-flex align-items-center gap-1 py-1">
                <div className="col-10">
                  <FontAwesomeIcon icon={faBellConcierge} color={'red'}/> &nbsp;
                  Incoming Alert from {message.sourceIp}
                  <span className="text-muted text-truncate d-block">{message.data}</span>
                </div>
                <div className="col-2">
                  <small className="text-muted"><Moment fromNow>{message.createdDate}</Moment></small>
                </div>
              </div>
            </ListGroupItem>
          ))}
        </ListGroup>
        <AlertPopup show={showPopup} onClose={handleClosePopup} message={message} />
      </CardBody>
    </Card>
  );
};

export default Alerts;

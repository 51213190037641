import { useState } from "react";
import { Button, Label, Alert, Input, Form, FormGroup, Card, CardBody, Modal, ModalBody, ModalHeader } from "reactstrap";
const AlertTestForm = ({ show, onClose, accountType }) => {

  const [textMessage, setTextMessage] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertVisible, setAlertVisible] = useState(false);

  const handleChange = (e) => {
    setTextMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setAlertMessage('');
    // Verify the JSON
    try {
      const jsonData = JSON.parse(textMessage);
      if (accountType === 'LIVE') {
        // Prompt message if the account type is LIVE
        const proceed = window.confirm("This order will be placed in live account. Click OK to proceed.");
        if (!proceed) {
          return;
        }
      }

      // Submit the form data
      fetch('/api/order/place-order', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(jsonData)
      })
        .then(response => {
          // Handle response
          onClose();
        })
        .catch(error => {
          // Handle error
          setAlertMessage(error.message);
        });
    } catch (error) {
      // JSON is invalid
      console.error(error);
      setAlertMessage(error.message);
      setAlertVisible(true);
    }
  };
  return (

    <Modal isOpen={show}>
      <ModalHeader>Test Send Alert Message</ModalHeader>
      <ModalBody>
        <Card color="light">
          <CardBody>
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="textMessage">Enter JSON</Label>
                <Input type="textarea" rows={10} name="textMessage" id="textMessage" value={textMessage} onChange={handleChange} />
              </FormGroup>
              <Button color="primary" type="submit">Submit</Button>
              <Alert color="danger" isOpen={alertVisible} toggle={() => setAlertVisible(false)}>{alertMessage}</Alert>
              &nbsp;
              <Button color="secondary" onClick={onClose}>Close</Button>
            </Form>
          </CardBody>
        </Card>
      </ModalBody>
    </Modal>
  );
};

export default AlertTestForm;
import Moment from 'react-moment';
import { Button, Card, CardBody, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import JsonViewer from "../shared/JsonViewer";

const AlertPopup = ({ show, onClose, message }) => {
  return (
    <>
      {message &&
        <Modal isOpen={show}>
          <ModalHeader>Alert Message</ModalHeader>
          <ModalBody>
            <Card color="light">
              <CardBody>
                {message.data &&
                  <JsonViewer data={JSON.parse(message.data)} />
                }
                <br />
                <dl>
                  <dt><span id="createdDate">Created Date</span></dt>
                  <dd><Moment format='DD/MM/yyyy HH:mm:ss'>{message.createdDate}</Moment></dd>
                </dl>
              </CardBody>
            </Card>
          </ModalBody>
          <ModalFooter>
            <Button variant="secondary" onClick={onClose}>Close</Button>
          </ModalFooter>
        </Modal>
      }
    </>
  );
};

export default AlertPopup;
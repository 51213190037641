import { Navigate } from "react-router-dom";

/****Layouts*****/
import FullLayout from '../layouts/FullLayout';

/***** Pages ****/
import Home from '../views/Home';


/*****Routes******/

const ThemeRoutes = [
  {
    path: "/",
    element: <FullLayout />,
    children: [
      { path: "/", element: <Navigate to="/home" /> },
      { path: "/home", exact: true, element: <Home /> },
    ],
  },
];

export default ThemeRoutes;

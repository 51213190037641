import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Card, CardBody, CardSubtitle, Alert } from 'reactstrap';

const UserProfileCard = ({webhookUrl}) => {

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertText, setAlertText] = useState("");

  const handleCopyText = async () => {
    try {
        await navigator.clipboard.writeText(webhookUrl);
        setAlertVisible(true);
        setAlertText("Webhook URL has been copied")

    } catch (err) {
        console.error('Failed to copy text: ', err);
        setAlertText('Failed to copy text. Please try again.');
    }
};
  return (
    <Card color="light">
      <CardBody className="border-top p-4">

          <CardSubtitle className="fs-5 mt-3 text-muted">WebHook URL</CardSubtitle>
          <h6 className="card-title">{webhookUrl} {`        `}
            <i style={{ cursor: 'pointer', color: 'grey' }} color="danger" onClick={handleCopyText}>
              <FontAwesomeIcon icon={faCopy} />{' '} Copy
            </i>
           </h6>
           <Alert color="success" isOpen={alertVisible} toggle={() => setAlertVisible(false)}>
                {alertText}
            </Alert>
          
          
      </CardBody>
    </Card>
  );
};

export default UserProfileCard;

import { Outlet } from "react-router-dom";
import { Container } from 'reactstrap';
import Header from "./Header";
import Footer from "./Footer";

const FullLayout = () => {
  
  return (
    <main>
      <div className="pageWrapper d-lg-flex">
          <div className="contentArea">
            <Header />
            <Container className="p-4 wrapper" fluid>
              <Outlet />
            </Container>
            <Footer/>
          </div>
        </div>  
      
    </main>
  );
};

export default FullLayout;
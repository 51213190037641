import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { faExclamationTriangle, faInstitution, faSignal, faUser } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import InfoCard from "../components/shared/InfoCard";
import Alerts from "../components/dashboard/Alerts";
import AlertTestForm from "../components/dashboard/AlertTestForm";
import UserProfileCard from "../components/dashboard/UserProfile";

const Home = () => {
  const [inspectBean, setInspectionBean] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const [webhookUrl, setWebHookUrl] = useState('');

  useEffect(() => {
    fetchTWSInfo();

    fetchWebhookUrl();

    const interval = setInterval(fetchTWSInfo, 5 * 60 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);


  const fetchTWSInfo = async () => {
    try {
      const response = await axios.get('/management/ibhealth');
      setInspectionBean(response.data);
    } catch (error) {
      console.error('Error fetching tws info:', error);
    }
  };

  const fetchWebhookUrl = async () => {
    try {
      const response = await axios.get('/api/order/url');
      setWebHookUrl(response.data);
      
    } catch (error) {
      console.error('Error fetching web hook url :', error);
    }
  };

  // const handleShowPopup = (message) => {
  //   // setMessage(message);
  //   setShowPopup(true);
  // };

  const handleClosePopup = () => {
    setShowPopup(false);
  };
  

  return (
    <div>
      <Row>
        <Col md={3}>
          <Row>
            
            <Col md={12}>
              <InfoCard color={inspectBean?.tws?.connected ? "success" : "danger"}
                label="TWS"
                icon={inspectBean?.tws?.connected ? faSignal : faExclamationTriangle}
                value={inspectBean?.tws?.connected ? "Connected" : "Disconnected"} />
            </Col>

            {inspectBean?.tws?.connected &&<>
              <Col md={12}>
                <InfoCard color="warning"
                  label="IB Account No"
                  icon={faUser}
                  value={inspectBean?.ibAccountNo} />
              </Col>
              <Col md={12}>
                
                <InfoCard color={inspectBean?.ibAccountNo?.startsWith('D')? 'dark': 'danger'}
                label="Account Type"
                icon={faInstitution}
                value={inspectBean?.ibAccountNo?.startsWith('D')? 'PAPER': 'LIVE'} />
              </Col>
            </>
            }
            <Col md={12}>
              <UserProfileCard webhookUrl={webhookUrl}/>
            </Col>
          </Row>
        </Col>
        <Col md={9}>
          <div className="d-flex flex-row justify-content-end p-1"> {/* Aligns content to the right */}
            {/* <Button color="success" onClick={() => handleShowPopup()}>Create Test Alert</Button> */}
          </div>
            <Alerts/>
        </Col>
      </Row>
      <AlertTestForm show={showPopup} onClose={handleClosePopup} accountType={inspectBean?.ibAccountNo?.startsWith('D')?'PAPER': 'LIVE'}/>
    </div>
  );
};

export default Home;
